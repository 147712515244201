/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import CTASection from '../../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Feature } from '../../components/Feature'
import Block from '../../components/Block'
import Page from '../../components/Page'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import Customers from '../../components/CustomerLogos'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import MetaImage from '../../assets/images/allstacks-meta.png'
import { LeadHeading } from '../../components/LeadHeading'
import { Link } from 'gatsby'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const SwarmiaVsAllstacks = () => {
  const SwarmiaVsAllstacksImages = useStaticQuery(graphql`
    query {
      feature1: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-investment-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-flow-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(relativePath: { eq: "images/new-issue-pop-up.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Allstacks vs. Swarmia"
      description="Explore the main differences between Allstacks and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../../assets/images/backgrounds/swarmia-space.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>Allstacks vs. Swarmia</h1>
          <div className="hero-description">
            <p className="large_text">
              The Allstacks team has done a great job in surfacing a wide
              variety of engineering metrics. Here at Swarmia, we’ve chosen to
              go deep instead of broad. That means we only show research-backed
              metrics in real time and allow you to see exactly what the
              aggregate numbers consist of.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="layout-index">
        <Block size="large" type="padding-bottom-small">
          <LeadHeading
            title="What are the main differences between Swarmia and Allstacks?"
            description="While Allstacks is a dashboard for the engineering leadership, Swarmia is focused on using healthy, real-time metrics as conversation starters and driving concrete improvements at every level of the engineering organization."
          />
          <div className="features full-width-images">
            <Feature
              type="feature feature-grid-right"
              heading="Real-time insights"
              image={
                SwarmiaVsAllstacksImages.feature1.childImageSharp
                  .gatsbyImageData
              }
              imageAlt="Real-time insights"
            >
              <p>
                An often-heard area where Allstacks falls short is the lack of
                real-time data and having to wait overnight to populate
                information from Jira. In Allstacks, Jira items don’t always get
                automatically pulled in and some users have reported metrics to
                be finicky at times.
              </p>
              <p>
                In contrast, Swarmia shows your data in real time. This is
                especially useful when you’re using Swarmia in daily standups,
                retrospectives, and other team ceremonies. Additionally, many of
                the developers who use Swarmia have bookmarked the pull requests
                view which gives you a real-time overview of all your team’s
                open PRs and ensures that no PRs fall through the cracks.
              </p>
              <p>
                <Link to="/product/insights/">
                  Read more about real-time insights in Swarmia →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="No toxic metrics"
              image={
                SwarmiaVsAllstacksImages.feature2.childImageSharp
                  .gatsbyImageData
              }
              imageAlt="No toxic metrics"
            >
              <p>
                Like many analytics-heavy engineering productivity tools,
                Allstacks’ aim seems to be to surface as many metrics as humanly
                possible. The problem is, some of these metrics are nonsensical,
                while others are straight-up toxic. Allstacks goes as far as to
                encourage using individual reports “to see if your team members
                are performing above or below the team average.”
              </p>
              <p>
                In contrast, we’re extremely selective about what data we
                surface in Swarmia. We only show measures that have a proven
                correlation with business performance. We also focus on healthy
                team-level metrics that don’t make your engineers feel like
                they’re being spied on or unfairly compared.
              </p>
              <p>
                <Link to="/engineering-metrics/">
                  Read more about the metrics in Swarmia →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Fast and simple configuration"
              image={
                SwarmiaVsAllstacksImages.feature3.childImageSharp
                  .gatsbyImageData
              }
              imageAlt="Fast and simple configuration"
            >
              <p>
                Former Allstacks users tell us that pulling a meaningful
                dashboard together is anything but a walk in the park. Setting
                up new users, associating them with applications and teams, as
                well as linking teams to projects is challenging. Monitoring
                employee changes requires manual work.
              </p>
              <p>
                Swarmia, on the other hand, provides guided setup that only
                takes minutes. Your teams can be based on your GitHub teams — or
                set up in Swarmia — to ensure up-to-date team structure. And if
                you need any support, we’re more than happy to help.
              </p>
              <p>
                <Link to="/signup/">
                  Start your free 14-day Swarmia trial →
                </Link>
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="alex4" />
        <Customers />
      </Page>
      <CTASection>
        Swarmia is how the best engineering teams get better. Request a demo
        today.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/balancing-engineering-investments/',
          '/blog/space-framework/',
          '/blog/dora-metrics/',
        ]}
        heading="More from our blog"
      />
    </Layout>
  )
}

export default SwarmiaVsAllstacks
